import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/Testimonial.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
const Testimonial = () => {
  const testimonials = [
    {
      quote:
        "I have recently worked with the Mindstar team for a mobile app development project wherein they were tasked with developing a new UI. They did a commendable job understanding the requirement, suggesting improvements and refining the UI based on feedback. They were quick, proactive and patient and we like the final product delivered.",
      author: "Raj Rajarshi Chanda",
      possition: "CEO",
      company: "Silfra Technologies",
      image: "/images/raj-da-background.jpg",
    },
    {
      quote:
        "Mindstar Technology Solutions Pvt. Ltd. succeeded in building a more manageable solution that is much easier to maintain. I recently worked with the team for developing a Prescription management system as well as a patient registration process. Although they are a team, their project management reflects the excellence of a larger company. Their team delivers high-quality results, attentive service, and reasonable pricing.",
      author: "Amitabha Bhattacharya",
      possition: "Doctor",
      company: "",
      image: "/images/dr-background.jpg",
    },
  ];
  const CustomDots = ({ active, onClick }) => (
    <span
      className={`w-3 h-3 flex gap-y-5 flex-row space-x-3 rounded-full cursor-pointer  ${
        active ? "bg-yellow-500" : "bg-purple-900"
      }`}
      onClick={onClick}
    ></span>
  );
  const CustomArrow = ({ onClick, direction }) => (
    <div className={`custom-arrow ${direction}`} onClick={onClick}>
      {/* You can use your custom arrow icons or text here */}
      {direction === "prev" ? <FaChevronLeft /> : <FaChevronRight />}
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    appendDots: (dots) => (
      <div className="flex items-center justify-center mx-5 w-full">{dots}</div>
    ),
    customPaging: (i, active) => <CustomDots key={i} active={active} />,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div
      className="w-screen h-fit  bg-no-repeat"
      style={{ backgroundImage: "url(/images/slider_bg_1.png)" }}
    >
      <div className="flex justify-center flex-col items-center font-bold text-2xl pb-10">
        <h1 className="text-3xl font-bold text-black">Testimonials</h1>
        <div className="w-32 rounded-md h-[1.5px] bg-red-600"></div>
      </div>

      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="carousel-slide relative">
            <div className="flex justify-center items-center w-full">
              <img
                src={testimonial.image}
                className="md:h-[25rem] sm:h-[18rem] md:w-[30rem]"
                alt={`Testimonial ${index + 1}`}
                useMap={`#imagemap${index}`}
              />
              <div className="flex flex-col gap-2 text-quote">
                <div className=" ">"{testimonial.quote}"</div>
                <div className="md:text-lg sm:text-xs font-medium">
                  "{testimonial.possition}"
                </div>
              </div>
              <div className="flex flex-col  text-author">
                <div className="">{testimonial.author}</div>
                <div className="md:text-sm sm:text-xs">
                  {testimonial.company}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonial;
