import React, { useState } from "react";

import VirtualTrialRoom from "./VirtualTrialRoom";
import WebsiteDevelopment from "./WebsiteDevelopment";
import InventoryManagement from "./InventoryManagement";
import MobileAppDevelopment from "./MobileAppDevelopment";
import { FaRegWindowClose } from "react-icons/fa";

const projects = [
  {
    imgsrc: "images/project1.png",
    componentName: "VirtualTrialRoom",
  },
  {
    imgsrc: "images/project2.png",
    componentName: "WebsiteDevelopment",
  },
  {
    imgsrc: "images/project3.png",
    componentName: "InventoryManagement",
  },
  {
    imgsrc: "images/project4.png",
    componentName: "MobileAppDevelopment",
  },
];

const OurWork = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(null);

  const openModal = (imgSrc, componentName) => {
    setSelectedImg(imgSrc);
    setSelectedComponent(componentName);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="lg:p-16 sm:p-8 h-fit">
      <div className="flex justify-center flex-col items-center font-bold text-2xl pb-10">
        <h1 className="text-3xl font-bold text-black">Our Project</h1>
        <div className="w-32 rounded-md h-[1.8px] bg-red-600"></div>
      </div>
      <div className="w-full flex flex-wrap gap-16 justify-center items-center">
        {projects.map((project, index) => (
          <div
            key={index}
            className="w-[18rem] h-[14rem] bg-blue-400 rounded-lg cursor-pointer"
            onClick={() => openModal(project.imgsrc, project.componentName)}
          >
            <img src={project.imgsrc} alt="" className="h-full w-full" />
          </div>
        ))}
      </div>
      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-10">
          <div className="bg-white  sm:w-[85%] xl:w-[75%] h-[90vh] overflow-y-scroll  rounded-lg">
            <div className="w-full flex p-4 justify-end items-center">
              <button
                className=" bg-[#5E2478] text-white lg:w-16 lg:h-10 sm:w-12 sm:h-8  flex items-center justify-center  rounded-lg hover:bg-[#471f59]"
                onClick={closeModal}
              >
                <FaRegWindowClose className="lg:text-2xl sm:text-xl" />
              </button>
            </div>

            {selectedComponent === "VirtualTrialRoom" && <VirtualTrialRoom />}
            {selectedComponent === "WebsiteDevelopment" && (
              <WebsiteDevelopment />
            )}
            {selectedComponent === "InventoryManagement" && (
              <InventoryManagement />
            )}
            {selectedComponent === "MobileAppDevelopment" && (
              <MobileAppDevelopment />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurWork;
