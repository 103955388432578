import React from "react";
import OurWork from "./OurWork";

const OurProject = () => {
  return (
    <div id="our-project">
      <OurWork />
    </div>
  );
};

export default OurProject;
