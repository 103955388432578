import React, { useEffect, useMemo, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";
import { FiMenu } from "react-icons/fi";

const Header = () => {
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);
  const ourprojectsRef = useRef(null);
  const contactUsRef = useRef(null);

  const NavElement = useMemo(
    () => [
      { name: "Home", link: "/" },
      { name: "About us", link: "#about-us", ref: aboutUsRef },
      { name: "Our Services", link: "#our-services", ref: ourServicesRef },
      { name: "Our Projects", link: "#our-project", ref: ourprojectsRef },

      { name: "Contact Us", link: "#contact-us", ref: contactUsRef },
    ],
    [aboutUsRef, ourServicesRef, ourprojectsRef, contactUsRef]
  );

  let [openMenu, setopenMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const activeLink = NavElement.find((link) => link.link === currentPath);
    if (activeLink) {
      setActiveLink(activeLink.name);
    }
  }, [NavElement]);

  const handleMenuClick = (link) => {
    if (link.ref && link.ref.current) {
      link.ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      window.location.href = link.link;
    }
    setopenMenu(false);
    setActiveLink(link.name);
  };

  return (
    <div className="w-full bg-white  md:h-20 sm:h-fit flex flex-row justify-around items-center sm:flex-col md:flex-row fixed z-10">
      <div className="w-[40%] flex justify-center sm:justify-between sm:p-[4%] sm:items-center sm:w-full sm:h-16 md:justify-center md:w-[40%]">
        <img
          src="/images/mindstarlogo1.png"
          alt="logo"
          className="sm:w-[11rem] lg:w-[15rem] "
        />

        <button
          onClick={() => setopenMenu(!openMenu)}
          className="md:hidden sm:block"
        >
          {openMenu ? <RxCross2 /> : <FiMenu />}
        </button>
      </div>
      <div
        className={`flex w-[40%] md:w-full md:flex-row md:justify-around md:static sm:top-[100%] sm:z-[99] sm:absolute  sm:flex-col sm:w-full ${
          openMenu ? "block" : "hidden md:flex"
        }`}
      >
        {NavElement.map((link) => (
          <div
            key={link.name}
            className={`sm:text-xl md:text-xs lg:text-sm xl:text-xl sm:w-full hover:underline hover:underline-offset-8  md:ml-0 sm:p-[2%] md:p-0 hover:decoration-[#CC7722] sm:flex sm:justify-center opacity-80 bg-white font-medium ${
              activeLink === link.name
                ? "text-[#592476] underline underline-offset-8 decoration-[#CC7722]"
                : "text-black"
            }`}
          >
            <button onClick={() => handleMenuClick(link)}>{link.name}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Header;
