import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MockUpcomponent = ({ pictures }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col gap-8 sm:p-4 md:p-8">
      <div className="w-full flex justify-center items-center">
        <span className="text-2xl font-bold">MOCK UPS</span>
      </div>
      <Slider {...settings}>
        {pictures.map((picture, index) => (
          <div className="carousel-slide" key={index}>
            <div className="flex justify-center items-center">
              <img
                src={picture.imgsrc}
                alt=""
                className="sm:w-[15rem] md:w-[18rem] sm:h-[23rem]  lg:h-[30rem]"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MockUpcomponent;
