import React from "react";
import { FaCircle } from "react-icons/fa";
import SubBanner from "./SubBanner";
const InventoryManagementComponent = () => {
  return (
    <div>
      <SubBanner
        name1="INVENTORY"
        name2="MANAGEMENT"
        name3=""
        name4=""
        imgsrc="images/project_3_ban.png"
      />
      <div className="w-full flex flex-col gap-6 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <img
            src="images/project_3_intro.png"
            alt=""
            className="md:w-[25rem] md:h-[25rem] sm:w-[18rem] sm:h-[18rem]"
          />
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-6">
            <span className="text-2xl font-bold text-[#373737]">
              INRODUCTION
            </span>
            <span className="flex flex-col gap-4 md:text-xl sm:text-lg text-[#666] text-justify ">
              <span>
                The project's objective is to provide an efficient inventory
                control whose main functionality apart from maintaining the
                inventory, includes cost calculation, and package distribution
                to the vendors and sales representatives. Besides all of that,
                the system allows the Sales representatives to place orders from
                clients’ locations capturing the latitude and longitude of that
                location. The system also keeps track of the inventory of the
                raw materials.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold text-[#373737]">
            TECHNOLOGY USED
          </span>
        </div>
        <div className="flex  justify-center sm:gap-2 md:gap-10 text-[#666]">
          <div className="flex flex-col gap-4">
            <span className="md:text-xl sm:text-lg font-bold ">
              Website Design{" "}
            </span>
            <span className="md:text-lg sm:text-base">Figma</span>
          </div>
          <div className="flex flex-col gap-4">
            <span className="md:text-xl sm:text-lg font-bold">
              Website Development{" "}
            </span>
            <span className="flex gap-3 md:text-lg sm:text-base">
              <span className="font-semibold">UI Design</span>
              <span> - NextJS</span>
            </span>
            <span className="flex gap-3 md:text-lg sm:text-base">
              <span className="font-semibold">API</span>
              <span> - Prisma</span>
            </span>{" "}
            <span className="flex gap-3 md:text-lg sm:text-base">
              <span className="font-semibold">Database</span>
              <span> - SQL , tRPC</span>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold text-[#373737]">ADVANTAGES</span>
        </div>
        <div className="flex flex-col gap-4 text-[#666]  sm:text-sm md:text-lg xxl:text-2xl text-justify">
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Enhances productivity in operations
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Reduces manual work
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Manage multiple warehouses
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Centralized Stock movement control
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold text-[#373737]">MOCK UPS</span>
        </div>
        <div className="flex justify-evenly flex-wrap gap-y-6">
          <div>
            <img
              src="images/project_3_mock_1.png"
              alt=""
              className="w-[25rem] rounded-lg"
            />
          </div>
          <div>
            <img
              src="images/project_3_mock_2.png"
              alt=""
              className="w-[25rem] rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InventoryManagementComponent;
