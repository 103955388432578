import React from "react";
import Banner from "./component/Banner";
import MissionVision from "./component/MissonVisson";
import OurService from "./component/OurService";
import Testimonial from "./component/Testimonial";
import AboutSection from "./component/AboutSection";
import Footer from "./component/Footer";
import MainPageTemplate from "./template/MainPageTemplate";
import OurProject from "./component/OurProject";

import ContactUs from "./component/ContactUs";
const Home = () => {
  return (
    <MainPageTemplate className=" ">
      <Banner />
      <AboutSection />
      <MissionVision />
      <OurService />
      <Testimonial />
      <OurProject />
      <ContactUs />
      <Footer />
    </MainPageTemplate>
  );
};

export default Home;
