import React from "react";

const SubBanner = (props) => {
  return (
    <div>
      <div className=" relative">
        <img
          src={props.imgsrc}
          alt=""
          className="w-full resize sm:h-[200px] md:h-[300px] lg:h-[400px]"
        />
        <div className=" absolute w-full inset-0 flex  justify-end">
          <div className="w-1/2 flex justify-center flex-col items-center sm:text-xl md:text-2xl font-bold text-white ">
            <span>{props.name1}</span>
            <span>{props.name2} </span>
            <span>{props.name3}</span>
            <span>{props.name4} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubBanner;
