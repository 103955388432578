import React from "react";
import { FaCircle } from "react-icons/fa";
import MockUpcomponent from "./MockUpcomponent";
import SubBanner from "./SubBanner";

const VirtualTrialRoomComponent = () => {
  const pictures = [
    { imgsrc: "images/pro_1_mock_1.png" },
    { imgsrc: "images/pro_1_mock_2.png" },
    { imgsrc: "images/pro_1_mock_3.png" },
    { imgsrc: "images/pro_1_mock_4.png" },
    { imgsrc: "images/pro_1_mock_5.png" },
    { imgsrc: "images/pro_1_mock_6.png" },
  ];
  return (
    <div className="">
      <SubBanner
        name1="3D VIRTUAL"
        name2="TRIAL ROOM"
        name3=""
        name4=""
        imgsrc="images/project_1_ban.png"
      />
      <div className="w-full flex  sm:flex-col gap-6 sm:p-4 md:p-8">
        <div className=" sm:w-full flex justify-center items-center">
          <img
            src="images/project_1_intro.png"
            alt=""
            className="md:w-[25rem] md:h-[25rem] sm:w-[18rem] sm:h-[18rem]"
          />
        </div>
        <div className=" sm:w-full">
          <div className="flex flex-col gap-6">
            <span className="text-2xl font-bold">INRODUCTION</span>
            <span className="flex flex-col gap-4 md:text-xl sm:text-lg text-[#666] text-justify ">
              <span>
                The 3D Virtual Trial Room Application will help the user try on
                dresses and apparels from the comfort of their homes virtually.
              </span>
              <span>
                This application is being developed with the adverse effects of
                the Covid-19 pandemic in mind, wherein, a user is unable to
                visit stores in person to try on what they want to buy.
              </span>
              <span>
                However, this is not limited to a pandemic environment as in the
                long term, it will eliminate the need for a user to physically
                visit any store just to try on a dress or an apparel to decide
                how it would look on them.
              </span>
              <span>
                This application promotes a shift in lifestyle and approach to
                shopping - which has also proven to be the need of the hour.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold">TECHNOLOGY USED</span>
        </div>
        <div className="md:text-xl sm:text-lg flex flex-col gap-6 text-justify text-[#666]">
          <span>
            The 3D Virtual Trial Room Application will use Machine Learning and
            AI Techniques to perfectly map the dress/apparel on the user’s
            concerned body part and provide a realistic representation of the
            end look. It will also allow the user to view the said end look from
            various different angles to decide whether he/she wants to buy into
            that look or not.
          </span>
          <span>
            This AI Technology will be sitting on a cutting edge Ecommerce
            application (included in the product) that will be responsible for
            implementing a seamless Ecommerce experience.
          </span>
          <span>This application will be available in:</span>
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" /> Web
          </span>
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" /> Android
          </span>
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" /> iOS platforms
          </span>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold">ADVANTAGES</span>
        </div>
        <div className="flex flex-col gap-4 text-[#666] md:text-xl sm:text-lg text-justify">
          <span>Trial from the comfort of your home.</span>
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Protects you from exposure to a pandemic (when needed).
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            What you order from normal E- Commerce sites may not be to your
            liking once it arrives.
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            High Customer Satisfaction = Less Return of Orders
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Sellers will not run out of business in a lockdown environment.
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Zero maintenance cost on products for sellers as customers will not
            physically try them on.
          </span>
        </div>
      </div>
      <MockUpcomponent pictures={pictures} />
    </div>
  );
};

export default VirtualTrialRoomComponent;
