import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Banner() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  const slides = [
    {
      imageSrc: "/images/banner1_back.png",
      title: "Flawless Software Solutions",
      secondImgSrc: "/images/banner_1.png",
    },
    {
      imageSrc: "/images/banner2_back.png",
      title: "Flawless Software Solutions",
      secondImgSrc: "/images/banner_2.png",
    },
    {
      imageSrc: "/images/banner3_back.png",
      title: "Flawless Software Solutions",
      secondImgSrc: "/images/banner_3.png",
    },
  ];

  return (
    <div className="relative">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="carousel-item relative" key={index}>
            <div
              className="w-full resize sm:h-[350px] md:h-[400px] lg:h-[600px]  bg-center bg-no-repeat bg-[length:100%_100%]"
              style={{
                backgroundImage: `url(${slide.imageSrc})`,
              }}
            >
              <div className="absolute inset-0 flex sm:flex-col md:flex-row items-center justify-between gap-10  sm:p-4 lg:p-8 font-[Montserrat] font-bold text-white sm:text-3xl md:text-5xl xl:text-7xl w-full">
                <div className="md:w-[50%] sm:w-full flex md:justify-center sm:justify-start items-center">
                  <p className="custom-text-stroke">{slide.title}</p>
                </div>
                <div className="md:w-[50%] sm:w-full flex justify-start items-center">
                  <img
                    src={slide.secondImgSrc}
                    alt=""
                    className="md:h-fit sm:h-[12rem] w-fit"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Banner;
