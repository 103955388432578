import React from "react";
import Header from "../component/Header";

const MainPageTemplate = (props) => {
  return (
    <div>
      <div className="flex w-screen h-screen flex-col overflow-y-auto overflow-x-hidden static">
        <div className="">
          <Header />
        </div>
        <div className="sm:mt-[70px] md:mt-[80px]">{props.children}</div>
      </div>
    </div>
  );
};

export default MainPageTemplate;
