import React from "react";

import WebsiteDevelopmentComponent from "./WebsiteDevelopmentComponent";

const WebsiteDevelopment = () => {
  return (
    <div>
      <WebsiteDevelopmentComponent />
    </div>
  );
};

export default WebsiteDevelopment;
