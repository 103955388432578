import React from "react";
import Contact from "./Contact";

const ContactUs = () => {
  return (
    <div id="contact-us">
      <Contact />
    </div>
  );
};

export default ContactUs;
