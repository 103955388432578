import React from "react";
import { FaCircle } from "react-icons/fa";
import MockUpcomponent from "./MockUpcomponent";
import SubBanner from "./SubBanner";
const MobileAppDevelopmentComponent = () => {
  const pictures = [
    { imgsrc: "images/APP Development_Slide 3.png" },
    { imgsrc: "images/APP Development_Slide 3_1.png" },
    { imgsrc: "images/APP Development_Slide 3_2.png" },
    { imgsrc: "images/APP Development_Slide 3_3.png" },
    { imgsrc: "images/APP Development_Slide 3_4.png" },
  ];
  return (
    <div>
      <SubBanner
        name1="MOBILE APP"
        name2="DEVELOPMENT"
        name3=""
        name4=""
        imgsrc="images/porject_4_ban.png"
      />
      <div className="w-full flex flex-col gap-6 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <img
            src="images/project_4_intro.png"
            alt=""
            className="md:w-[25rem] md:h-[25rem] sm:w-[18rem] sm:h-[18rem]"
          />
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-6">
            <span className="text-2xl font-bold text-[#373737]">
              INRODUCTION
            </span>
            <span className="flex flex-col gap-4 md:text-xl sm:text-lg text-[#666] text-justify ">
              <span>
                This is a software application used to store employee
                information and support various human resource functions, such
                as benefits, payroll, recruiting, training, etc. This app
                provides an all-in-one Human Resource experience.
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold text-[#373737]">
            TECHNOLOGY USED
          </span>
        </div>
        <div className="flex  justify-center sm:gap-2 md:gap-10 text-[#666]">
          <div className="flex flex-col gap-4">
            <span className="md:text-xl sm:text-lg font-bold ">
              Website Design{" "}
            </span>
            <span className="md:text-lg sm:text-base font-semibold">Figma</span>
          </div>
          <div className="flex flex-col gap-4">
            <span className="md:text-xl sm:text-lg font-bold">
              Website Development{" "}
            </span>
            <span className="flex gap-3 md:text-lg sm:text-base">
              <span className="font-semibold">Flutter</span>
            </span>
            <span className="flex gap-3 md:text-lg sm:text-base">
              <span className="font-semibold">Firebase</span>
            </span>{" "}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 sm:p-4 md:p-8">
        <div className="w-full flex justify-center items-center">
          <span className="text-2xl font-bold text-[#373737]">ADVANTAGES</span>
        </div>
        <div className="flex flex-col gap-4 text-[#666]  sm:text-sm md:text-lg xxl:text-2xl text-justify">
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Improves organization of documents and employee data
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Streamlined processes
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            More accurate analytics
          </span>{" "}
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Manages attendance and work time
          </span>
          <span className="flex items-center gap-2">
            <FaCircle className="text-xs" />
            Upgraded Employee Experience & Error-free payroll
          </span>
        </div>
      </div>
      <MockUpcomponent pictures={pictures} />
    </div>
  );
};

export default MobileAppDevelopmentComponent;
