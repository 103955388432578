import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PiCaretDoubleUpBold } from "react-icons/pi";
import { CiPaperplane } from "react-icons/ci";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
const CustomDots = ({ active, onClick }) => (
  <span
    className={`w-7 h-2 flex gap-y-5 flex-row space-x-3  cursor-pointer bg-purple-900`}
    onClick={onClick}
  ></span>
);

const servicesData = [
  {
    title1: "Application",
    title2: "Development",
    image: "/images/appdevelopment.png",
    hoverText: [
      "Web Application Development",
      "Mobile App Development (iOS & Android)",
      "Hybrid App Development",
      "AI & ML Application Development",
      "E-Commerce Application Development",
      "Payment Gateway Integration",
      "SEO Services",
    ],
  },
  {
    title1: "Software",
    title2: "Testing",
    image: "/images/software tasting.png",
    hoverText: ["Automation Testing", "Manual Testing"],
  },
  {
    title1: "Graphics",
    title2: "Design",
    image: "/images/Group 15.png",
    hoverText: [
      "UI Design",
      "UX Design",
      "Animation",
      "Virtual Reality",
      "Logo Design",
      "Brochure Design",
      "Banner & Poster Design for A-B Testing & online campaigning",
      "Business card and Letterhead Designing",
    ],
  },
  {
    title1: "Domain",
    title2: "Specialization",
    image: "/images/Group 16.png",
    hoverText: [
      "Artificial Intelligence",
      "Machine Learning",
      "Blockchain",
      "Asynchronous Web Development",
      "Messaging Servers (Publisher/Subscriber)",
      "Performance Optimization",
      "Data Warehousing",
      "Data lake",
    ],
  },
  {
    title1: "Development",
    title2: "Frameworks",
    image: "/images/development framework.png",
    hoverText: [
      "Express",
      "Elixir",
      "Spring",
      "Struts",
      "EjB",
      "jMS",
      "jPA",
      "Laravel",
      "Codeigniter",
      "Flask",
      "Sanic",
      "Async.io",
    ],
  },
  {
    title1: "Languages",
    title2: "",
    image: "/images/Languages.png",
    hoverText: [
      "Golang",
      "Node.js",
      "Python",
      "Java",
      "Scala",
      "PHP",
      "Dart",
      "React Native",
    ],
  },
  {
    title1: "Open Source",
    title2: "Products & Libraries",
    image: "/images/openSourceProduct.png",
    hoverText: [
      "Apache Hadoop",
      "Apache Spark",
      "Apache Kafka",
      "Redis",
      "Nats",
      "Scikit-learn",
      "Panda",
      "Dask",
      "Bert Model",
      "DBT",
    ],
  },
  {
    title1: "Databases",
    image: "/images/database.png",
    hoverText: ["MySQL", "PostgreSQL", "MongoDB", "CouchDB"],
  },
  {
    title1: "Web & Application ",
    title2: "Servers",
    image: "/images/web and application servers.png",
    hoverText: ["NGINX", "Mode.js", "Apache Tomcat", "Apache", "jBoss"],
  },
  {
    title1: "Platform ",
    title2: "& OS",
    image: "/images/platform & os.png",
    hoverText: ["Linux", "Mac OS", "Windows", "Android", "iOS"],
  },
];

const OurService = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 725) {
        setSlidesToShow(1);
      } else if (window.innerWidth <= 1024) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1780) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(4);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const CustomArrow = ({ onClick, direction }) => (
    <div className={`custom-arrow ${direction}`} onClick={onClick}>
      {direction === "prev" ? <FaChevronLeft /> : <FaChevronRight />}
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,

    autoplaySpeed: 3000,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    appendDots: (dots) => (
      <div className="flex items-center justify-center mx-5 w-full">{dots}</div>
    ),
    customPaging: (i) => <CustomDots key={i} />,
  };

  return (
    <div
      id="our-services"
      className="p-8 w-full bg-no-repeat scroll-animation"
      style={{ backgroundImage: "url(/images/slider-dot-img-1.png)" }}
    >
      <div className="w-full flex flex-col justify-center items-center p-8">
        <h1 class="text-3xl font-bold text-black">Our Services</h1>
        <div class="w-32 rounded-md h-[1.5px] bg-red-600"></div>
      </div>
      <Slider {...settings}>
        {servicesData.map((service, index) => (
          <div
            className="carousel-slide "
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="flex justify-center items-center flex-col  relative">
              <div className=" sm:w-[18rem] xl:w-[25rem] h-[12rem] rounded-t-md shadow-xl flex-col font-bold sm:text-lg lg:text-2xl text-white flex  justify-center items-center bg-gradient-to-b from-orange-800 via-orange-500 to-orange-300">
                <span>{service.title1}</span>
                <span>{service.title2}</span>
              </div>
              <div className=" sm:w-[18rem] xl:w-[25rem] h-[20rem] bg-[#5E2478] shadow-xl flex items-center justify-center">
                <img
                  src={service.image}
                  alt=""
                  className="w-[16rem] h-[16rem]"
                />
              </div>
              <div className=" sm:w-[18rem] xl:w-[25rem] h-[2rem] shadow-xl shadow-slate-400 bg-white rounded-b-md flex justify-center items-center">
                <PiCaretDoubleUpBold className="text-3xl text-[#5E2478]" />
              </div>
              <div className="border-element "></div>
              <ul
                className={`absolute bottom-0 top-[-5px]  p-6  sm:w-[18rem] xl:w-[25rem] h-[34.5rem] rounded-xl shadow-xl  bg-white transform transition-transform duration-300 ${
                  hoveredIndex === index
                    ? "translate-y-0 opacity-100"
                    : "translate-y-full opacity-0"
                }`}
              >
                <li className="lg:text-2xl sm:text-xl font bold  text-gray-700">
                  Services that we offer:
                </li>
                {service.hoverText.map((text, i) => (
                  <div>
                    <li
                      key={i}
                      className="lg:text-xl sm:text-lg flex items-start justify-start text-start   text-gray-700"
                    >
                      <CiPaperplane className="flex items-center  text-[#5E2478] w-6 h-6" />
                      {text}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OurService;
