import React from "react";
import { BiBody } from "react-icons/bi";

import { FaEnvelope } from "react-icons/fa";
import { TbMessageCircle2Filled } from "react-icons/tb";
import { FaLocationPin } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="lg:p-16 sm:p-8 h-fit">
      <div className="flex justify-center flex-col items-center font-bold text-2xl pb-10">
        <h1 className="text-3xl font-bold text-black">Contact Us</h1>
        <div className="w-32 rounded-md h-[1.5px] bg-red-600"></div>
      </div>
      <div className="w-full h-fit  justify-around items-center flex sm:flex-col sm:gap-4 lg:gap-0 lg:flex-row">
        <div className="flex flex-col gap-8 ">
          <h2 className=" sm:text-2xl lg:text-5xl font-bold">Contact Form</h2>
          <div className="  h-[3rem] sm:w-[22rem] md:w-[32rem] xl:w-[48rem] flex justify-center items-center p-2 border-2 border-[#c2c0c0]">
            <input
              className=" h-full w-full  text-lg "
              type="text"
              placeholder="Your Name"
            />
            <BiBody className=" h-6 w-6" />
          </div>
          <div className=" flex h-[3rem] sm:w-[22rem] md:w-[32rem] xl:w-[48rem] sm:flex-col lg:flex-row gap-4">
            <div className="h-full w-full flex items-center p-2 justify-center  border-2 border-[#c2c0c0]">
              <input
                className="h-full w-full  text-lg "
                type="text"
                placeholder="Email Address"
              />
              <FaEnvelope className=" h-6 w-6" />
            </div>
            <div className="h-full w-full p-2 flex items-center justify-center border-2 border-[#c2c0c0]">
              <input
                className="h-full w-full  text-lg"
                type="text"
                placeholder="Subject"
              />
              <TbMessageCircle2Filled className=" h-6 w-6" />
            </div>
          </div>
          <div className="h-[14rem] sm:mt-10 lg:mt-0 sm:w-[22rem] md:w-[32rem] xl:w-[48rem] border-2 border-[#c2c0c0] p-3 text-lg">
            <textarea
              className="h-full w-full "
              name=""
              id=""
              placeholder="Your Message"
            ></textarea>
          </div>
          <div>
            <a href="mailto:techmindstar@gmail.com">
              <button className=" h-[3rem] sm:w-[9rem] lg:w-[12rem] bg-[#241b1b] text-lg  text-white">
                Submit Now
              </button>
            </a>
          </div>
        </div>
        <div className="flex flex-col sm:gap-5 lg:gap-20 lg:pb-24">
          <h2 className=" sm:text-2xl lg:text-5xl font-bold">Contact info</h2>
          <div className="flex flex-col gap-10">
            <div className="flex flex-row gap-5 items-center">
              <div className=" sm:h-16 sm:w-16 md:h-20 md:w-20 lg:w-16 lg:h-16 xl:h-20 xl:w-20 rotate-45 flex items-center justify-center border-4 border-black ">
                <FaLocationPin className=" xl:h-9 xl:w-9 sm:h-7 sm:w-7 md:h-11 md:w-11 lg:h-8 lg:w-8 -rotate-45" />
              </div>
              <div className=" w-52 ">
                <h4 className="text-xl font-bold">Address</h4>
                <p>18C /2, 2no Poddar Nagar, Jadavpur, Kolkata 700032</p>
              </div>
            </div>
            <div className="flex flex-row gap-5 items-center">
              <div className=" sm:h-16 sm:w-16 md:h-20 md:w-20 lg:w-16 lg:h-16 xl:h-20 xl:w-20 rotate-45 flex items-center justify-center border-4 border-black ">
                <FaPhone className=" xl:h-9 xl:w-9 sm:h-7 sm:w-7 md:h-11 md:w-11 lg:h-8 lg:w-8 -rotate-45" />
              </div>
              <div className=" w-52 ">
                <h4 className="text-xl font-bold">Phone</h4>
                <p>9674899505</p>
              </div>
            </div>
            <a href="mailto:techmindstar@gmail.com">
              <div className="flex flex-row gap-5 items-center">
                <div className=" sm:h-16 sm:w-16 md:h-20 md:w-20 lg:w-16 lg:h-16 xl:h-20 xl:w-20 rotate-45 flex items-center justify-center border-4 border-black ">
                  <FaEnvelope className=" xl:h-9 xl:w-9 sm:h-7 sm:w-7 md:h-11 md:w-11 lg:h-8 lg:w-8 -rotate-45" />
                </div>
                <div className=" w-52 ">
                  <h4 className="text-xl font-bold">Email</h4>
                  <p>techmindstar@gmail.com</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
