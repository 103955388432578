import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/MissonVisson.css";
const MissionVision = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: "9rem",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0rem",
        },
      },
    ],
  };

  const slides = [
    {
      imgsrc: "/images/oneP.png",
      text: "Our mission is: to deliver the best possible reliable software Solutions to help our clients improve IT efficiency and business profitability.",
      subimgsrc: "/images/01.png",
    },
    {
      imgsrc: "/images/oneP2.png",
      text: "To be the benchmark for Software Services in the IT industry recognized for the integrity of our people, the ethics of our business practice and the quality of our service",
      subimgsrc: "/images/02.png",
    },
  ];
  return (
    <div
      className="mission-vision-container"
      style={{ backgroundImage: "url(/images/ban2.png)" }}
    >
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="slider-item" key={index}>
            <div className="content-container">
              <div>
                <img src={slide.imgsrc} className="slide-image" alt="" />
                <p className="slide-text">{slide.text}</p>
              </div>
              <div className="">
                <img src={slide.subimgsrc} className="sub-image" alt="" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MissionVision;
